import React from 'react';
import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <div className="app">
      <div className="logo-big">
      <svg viewBox="0 0 17 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7554 25.2116C15.7669 23.9608 15.78 22.6422 15.3331 21.0969L15.2621 20.9894C14.8152 19.4441 13.5434 18.829 12.137 19.6156L2.54646 24.9798C1.14007 25.7664 0.362241 27.6567 0.809127 29.202C1.25601 30.7473 2.75839 31.3623 4.16478 30.5757L13.7554 25.2116Z" fill="#76D7FE"/>
            <path d="M13.7553 18.9749C15.1617 19.7615 15.9395 21.6519 15.4926 23.1972C15.0457 24.7424 13.5433 25.3575 12.1369 24.5709L2.54637 19.2068C0.742624 18.0056 0.457204 16.5876 0.904089 15.0423L0.904089 14.9845C1.35097 13.4392 2.7583 12.8242 4.16469 13.6108L13.7553 18.9749Z" fill="#F24E1E"/>
            <path d="M13.7554 6.78845C15.1618 7.57506 15.8887 8.85618 15.4419 10.4015C14.995 11.9467 13.5435 13.171 12.1371 12.3844L2.54655 7.02032C1.14016 6.23371 0.362324 4.34334 0.80921 2.79805C1.2561 1.25277 2.75848 0.637743 4.16487 1.42435L13.7554 6.78845Z" fill="#62D8AB"/>
            <path d="M13.7553 13.0251C15.1617 12.2385 15.9395 10.3481 15.4926 8.80284C15.0457 7.25756 13.5433 6.64253 12.1369 7.42914L2.54637 12.7932C1.13998 13.5799 0.362151 15.4702 0.809037 17.0155C1.25592 18.5608 2.7583 19.1758 4.16469 18.3892L13.7553 13.0251Z" fill="#A259FF"/>
          </svg>
          
      </div>
      <div className="container">
        <header>
          <div className="logo"><svg viewBox="0 0 215 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.8227 55.7949C34.1839 53.0721 34.2122 50.2019 33.2433 46.8382L33.0894 46.6041C32.1205 43.2405 29.3632 41.9017 26.3141 43.6139L5.52098 55.2902C2.47182 57.0024 0.785432 61.1173 1.75431 64.4809C2.72319 67.8446 5.98045 69.1834 9.02962 67.4711L29.8227 55.7949Z" fill="#76D7FE"/>
<path d="M29.8228 42.2192C32.8719 43.9314 34.5583 48.0463 33.5894 51.4099C32.6206 54.7736 29.3633 56.1124 26.3141 54.4001L5.52105 42.7239C1.61038 40.1093 0.991571 37.0226 1.96045 33.6589L1.96045 33.5331C2.92933 30.1694 5.98053 28.8307 9.02969 30.5429L29.8228 42.2192Z" fill="#F24E1E"/>
<path d="M29.8228 15.6924C32.8719 17.4046 34.4479 20.1933 33.4791 23.5569C32.5102 26.9206 29.3633 29.5856 26.3141 27.8733L5.52106 16.1971C2.4719 14.4849 0.785499 10.37 1.75438 7.00632C2.72326 3.64264 5.98053 2.30389 9.0297 4.01613L29.8228 15.6924Z" fill="#62D8AB"/>
<path d="M29.8228 29.2681C32.8719 27.5559 34.5583 23.441 33.5894 20.0774C32.6206 16.7137 29.3633 15.3749 26.3141 17.0872L5.52105 28.7634C2.47189 30.4757 0.785492 34.5905 1.75437 37.9542C2.72325 41.3179 5.98052 42.6566 9.02969 40.9444L29.8228 29.2681Z" fill="#A259FF"/>
<path d="M50.7373 39.2594C50.1184 39.2594 49.6206 39.0708 49.2438 38.6935C48.8671 38.2893 48.6787 37.7773 48.6787 37.1574V12.5807C48.6787 11.9609 48.8536 11.4758 49.2035 11.1255C49.5802 10.7482 50.0915 10.5596 50.7373 10.5596H65.1879C66.4795 10.5596 67.1254 11.1255 67.1254 12.2573C67.1254 13.3891 66.4795 13.955 65.1879 13.955H52.7959V22.8884H64.3806C65.6722 22.8884 66.3181 23.4543 66.3181 24.5861C66.3181 25.7179 65.6722 26.2838 64.3806 26.2838H52.7959V37.1574C52.7959 37.8042 52.6075 38.3162 52.2308 38.6935C51.881 39.0708 51.3831 39.2594 50.7373 39.2594Z" fill="white"/>
<path d="M72.8792 39.2594C72.2872 39.2594 71.7894 39.0977 71.3857 38.7743C71.009 38.451 70.8206 37.9659 70.8206 37.3191V20.9077C70.8206 20.2609 71.009 19.7758 71.3857 19.4525C71.7894 19.1021 72.2872 18.927 72.8792 18.927C73.4712 18.927 73.9556 19.1021 74.3323 19.4525C74.7091 19.7758 74.8974 20.2609 74.8974 20.9077V37.3191C74.8974 37.9659 74.7091 38.451 74.3323 38.7743C73.9556 39.0977 73.4712 39.2594 72.8792 39.2594ZM72.8792 14.9656C72.0988 14.9656 71.4799 14.75 71.0224 14.3188C70.565 13.8877 70.3362 13.3217 70.3362 12.6211C70.3362 11.9204 70.565 11.368 71.0224 10.9638C71.4799 10.5326 72.0988 10.317 72.8792 10.317C73.6327 10.317 74.2382 10.5326 74.6956 10.9638C75.18 11.368 75.4222 11.9204 75.4222 12.6211C75.4222 13.3217 75.1935 13.8877 74.736 14.3188C74.2785 14.75 73.6596 14.9656 72.8792 14.9656Z" fill="white"/>
<path d="M96.9038 18.927C97.5227 18.927 98.0071 19.1156 98.3569 19.4929C98.7336 19.8432 98.922 20.3148 98.922 20.9077V37.4808C98.922 40.499 98.1147 42.7761 96.5001 44.3122C94.9124 45.8482 92.5578 46.6163 89.4363 46.6163C86.53 46.6163 84.1082 46.0638 82.1707 44.959C81.175 44.393 80.6772 43.7193 80.6772 42.9378C80.6772 42.4797 80.7983 42.1024 81.0404 41.806C81.3095 41.5096 81.6325 41.3614 82.0092 41.3614C82.359 41.3614 82.9107 41.5635 83.6641 41.9677C84.6329 42.4258 85.5344 42.7762 86.3686 43.0187C87.2028 43.2882 88.1984 43.4229 89.3556 43.4229C93.0691 43.4229 94.9259 41.55 94.9259 37.8042V34.6917C94.3608 35.8774 93.4997 36.8071 92.3425 37.4808C91.1854 38.1276 89.8399 38.451 88.3061 38.451C86.5839 38.451 85.05 38.0467 83.7045 37.2383C82.359 36.4298 81.3095 35.2845 80.5561 33.8024C79.8295 32.3202 79.4662 30.609 79.4662 28.6688C79.4662 26.7285 79.843 25.0173 80.5964 23.5351C81.3499 22.053 82.3859 20.9077 83.7045 20.0992C85.05 19.2638 86.5839 18.8461 88.3061 18.8461C89.813 18.8461 91.1316 19.1695 92.2618 19.8163C93.4189 20.463 94.2935 21.3793 94.8855 22.565V20.8672C94.8855 20.2744 95.0604 19.8028 95.4103 19.4525C95.787 19.1021 96.2848 18.927 96.9038 18.927ZM89.2748 35.2172C91.024 35.2172 92.3964 34.6378 93.392 33.479C94.3877 32.3202 94.8855 30.7168 94.8855 28.6688C94.8855 26.6476 94.3877 25.0577 93.392 23.8989C92.4233 22.7132 91.0509 22.1203 89.2748 22.1203C87.4988 22.1203 86.0995 22.6997 85.0769 23.8585C84.0812 25.0173 83.5834 26.6207 83.5834 28.6688C83.5834 30.7168 84.0947 32.3202 85.1173 33.479C86.1398 34.6378 87.5257 35.2172 89.2748 35.2172Z" fill="white"/>
<path d="M127.207 18.8461C131.62 18.8461 133.826 21.5005 133.826 26.8093V37.3191C133.826 37.9389 133.638 38.424 133.261 38.7743C132.885 39.0977 132.387 39.2594 131.768 39.2594C131.176 39.2594 130.691 39.0977 130.315 38.7743C129.938 38.424 129.75 37.9389 129.75 37.3191V26.8498C129.75 25.2059 129.454 24.0202 128.862 23.2926C128.27 22.538 127.341 22.1608 126.076 22.1608C124.596 22.1608 123.426 22.6728 122.565 23.6968C121.704 24.6939 121.273 26.0683 121.273 27.8199V37.3191C121.273 37.9389 121.085 38.424 120.708 38.7743C120.331 39.0977 119.833 39.2594 119.214 39.2594C118.622 39.2594 118.138 39.0977 117.761 38.7743C117.385 38.424 117.196 37.9389 117.196 37.3191V26.8498C117.196 25.2059 116.9 24.0202 116.308 23.2926C115.716 22.538 114.788 22.1608 113.523 22.1608C112.043 22.1608 110.859 22.6728 109.971 23.6968C109.11 24.6939 108.679 26.0683 108.679 27.8199V37.3191C108.679 37.9389 108.491 38.424 108.114 38.7743C107.737 39.0977 107.253 39.2594 106.661 39.2594C106.069 39.2594 105.571 39.0977 105.167 38.7743C104.791 38.424 104.602 37.9389 104.602 37.3191V20.8672C104.602 20.2744 104.804 19.8028 105.208 19.4525C105.611 19.1021 106.096 18.927 106.661 18.927C107.226 18.927 107.684 19.1021 108.033 19.4525C108.41 19.7758 108.598 20.234 108.598 20.8268V22.3224C109.19 21.1906 110.011 20.3283 111.061 19.7354C112.11 19.1426 113.321 18.8461 114.694 18.8461C116.2 18.8461 117.438 19.156 118.407 19.7758C119.403 20.3957 120.143 21.3523 120.627 22.6458C121.219 21.4871 122.094 20.5708 123.251 19.8971C124.408 19.1965 125.727 18.8461 127.207 18.8461Z" fill="white"/>
<path d="M147.684 18.8461C150.294 18.8461 152.231 19.5064 153.496 20.8268C154.788 22.1473 155.434 24.1549 155.434 26.8498V37.3191C155.434 37.9389 155.259 38.424 154.909 38.7743C154.559 39.0977 154.075 39.2594 153.456 39.2594C152.864 39.2594 152.393 39.0842 152.043 38.7339C151.693 38.3836 151.518 37.912 151.518 37.3191V35.8235C151.007 36.9553 150.24 37.8312 149.218 38.451C148.222 39.0438 147.051 39.3403 145.706 39.3403C144.414 39.3403 143.23 39.0842 142.154 38.5722C141.077 38.0333 140.23 37.2922 139.611 36.349C139.019 35.4058 138.723 34.3548 138.723 33.1961C138.723 31.7678 139.086 30.6495 139.813 29.841C140.566 29.0056 141.791 28.4128 143.486 28.0624C145.208 27.7121 147.563 27.5369 150.55 27.5369H151.478V26.3647C151.478 24.8556 151.169 23.7642 150.55 23.0905C149.931 22.4168 148.935 22.0799 147.563 22.0799C146.621 22.0799 145.733 22.2012 144.899 22.4437C144.064 22.6863 143.149 23.0366 142.154 23.4947C141.427 23.8989 140.916 24.101 140.62 24.101C140.216 24.101 139.88 23.9528 139.611 23.6564C139.369 23.36 139.248 22.9827 139.248 22.5246C139.248 22.1203 139.355 21.77 139.57 21.4736C139.813 21.1502 140.189 20.8403 140.701 20.5439C141.643 20.0319 142.746 19.6276 144.011 19.3312C145.275 19.0078 146.5 18.8461 147.684 18.8461ZM146.432 36.3086C147.912 36.3086 149.123 35.81 150.065 34.813C151.007 33.7889 151.478 32.4819 151.478 30.892V29.841H150.751C148.652 29.841 147.038 29.9353 145.908 30.124C144.777 30.3126 143.97 30.636 143.486 31.0941C143.001 31.5253 142.759 32.1586 142.759 32.9939C142.759 33.9641 143.109 34.7591 143.809 35.3789C144.508 35.9987 145.383 36.3086 146.432 36.3086Z" fill="white"/>
<path d="M111.367 63.8207C109.911 63.8207 108.652 63.5072 107.589 62.8803C106.526 62.2389 105.704 61.3277 105.122 60.1469C104.554 58.9514 104.27 57.5446 104.27 55.9264C104.27 54.3227 104.554 52.9305 105.122 51.7496C105.704 50.5542 106.526 49.643 107.589 49.0161C108.652 48.3747 109.911 48.054 111.367 48.054C112.342 48.054 113.259 48.2143 114.118 48.535C114.992 48.8412 115.749 49.2858 116.389 49.869C116.622 50.0877 116.739 50.3428 116.739 50.6344C116.739 50.8385 116.68 51.0207 116.564 51.1811C116.448 51.3268 116.309 51.3997 116.149 51.3997C115.974 51.3997 115.763 51.3196 115.516 51.1592C114.773 50.576 114.096 50.1678 113.485 49.9346C112.888 49.7013 112.197 49.5847 111.411 49.5847C109.722 49.5847 108.419 50.1387 107.502 51.2467C106.599 52.3401 106.148 53.9 106.148 55.9264C106.148 57.9674 106.599 59.5419 107.502 60.6498C108.419 61.7432 109.722 62.2899 111.411 62.2899C112.182 62.2899 112.874 62.1733 113.485 61.94C114.096 61.7068 114.773 61.2986 115.516 60.7154C115.763 60.5551 115.974 60.4749 116.149 60.4749C116.309 60.4749 116.448 60.5478 116.564 60.6936C116.68 60.8393 116.739 61.0216 116.739 61.2403C116.739 61.5318 116.622 61.787 116.389 62.0056C115.749 62.5888 114.992 63.0407 114.118 63.3614C113.259 63.6676 112.342 63.8207 111.367 63.8207Z" fill="white"/>
<path d="M123.552 63.7988C122.547 63.7988 121.659 63.5728 120.888 63.1209C120.131 62.669 119.541 62.0275 119.119 61.1965C118.711 60.351 118.507 59.3742 118.507 58.2662C118.507 57.1583 118.711 56.1888 119.119 55.3578C119.541 54.5123 120.131 53.8635 120.888 53.4116C121.659 52.9596 122.547 52.7337 123.552 52.7337C124.556 52.7337 125.444 52.9596 126.216 53.4116C126.987 53.8635 127.577 54.5123 127.984 55.3578C128.407 56.1888 128.618 57.1583 128.618 58.2662C128.618 59.3742 128.407 60.351 127.984 61.1965C127.577 62.0275 126.987 62.669 126.216 63.1209C125.444 63.5728 124.556 63.7988 123.552 63.7988ZM123.552 62.3337C124.6 62.3337 125.4 61.9911 125.954 61.3059C126.521 60.6061 126.805 59.5929 126.805 58.2662C126.805 56.9687 126.521 55.9701 125.954 55.2703C125.386 54.556 124.585 54.1988 123.552 54.1988C122.518 54.1988 121.717 54.556 121.15 55.2703C120.582 55.9701 120.298 56.9687 120.298 58.2662C120.298 59.5783 120.575 60.5842 121.128 61.284C121.695 61.9838 122.503 62.3337 123.552 62.3337Z" fill="white"/>
<path d="M143.089 52.7337C145.447 52.7337 146.626 54.1405 146.626 56.9542V62.8803C146.626 63.1573 146.546 63.376 146.386 63.5364C146.226 63.6822 146.015 63.7551 145.753 63.7551C145.491 63.7551 145.272 63.6822 145.097 63.5364C144.937 63.376 144.857 63.1573 144.857 62.8803V56.9979C144.857 56.0211 144.683 55.3141 144.333 54.8767C143.984 54.4394 143.438 54.2207 142.695 54.2207C141.807 54.2207 141.109 54.5195 140.599 55.1173C140.09 55.715 139.835 56.5241 139.835 57.5446V62.8803C139.835 63.1573 139.748 63.376 139.573 63.5364C139.413 63.6822 139.202 63.7551 138.94 63.7551C138.678 63.7551 138.466 63.6822 138.306 63.5364C138.146 63.376 138.066 63.1573 138.066 62.8803V56.9979C138.066 56.0211 137.891 55.3141 137.542 54.8767C137.193 54.4394 136.639 54.2207 135.882 54.2207C135.009 54.2207 134.31 54.5195 133.786 55.1173C133.277 55.715 133.022 56.5241 133.022 57.5446V62.8803C133.022 63.1573 132.942 63.376 132.782 63.5364C132.622 63.6822 132.41 63.7551 132.148 63.7551C131.886 63.7551 131.668 63.6822 131.493 63.5364C131.333 63.376 131.253 63.1573 131.253 62.8803V53.6521C131.253 53.3897 131.333 53.1783 131.493 53.018C131.668 52.8576 131.886 52.7774 132.148 52.7774C132.396 52.7774 132.6 52.8576 132.76 53.018C132.92 53.1637 133 53.3678 133 53.6303V54.6799C133.32 54.053 133.764 53.5719 134.332 53.2366C134.9 52.9013 135.555 52.7337 136.297 52.7337C137.957 52.7337 139.041 53.4334 139.551 54.833C139.857 54.1915 140.323 53.6813 140.949 53.3022C141.575 52.9232 142.288 52.7337 143.089 52.7337Z" fill="white"/>
<path d="M161.684 52.7337C164.042 52.7337 165.221 54.1405 165.221 56.9542V62.8803C165.221 63.1573 165.141 63.376 164.981 63.5364C164.821 63.6822 164.61 63.7551 164.348 63.7551C164.086 63.7551 163.867 63.6822 163.693 63.5364C163.533 63.376 163.453 63.1573 163.453 62.8803V56.9979C163.453 56.0211 163.278 55.3141 162.928 54.8767C162.579 54.4394 162.033 54.2207 161.291 54.2207C160.403 54.2207 159.704 54.5195 159.194 55.1173C158.685 55.715 158.43 56.5241 158.43 57.5446V62.8803C158.43 63.1573 158.343 63.376 158.168 63.5364C158.008 63.6822 157.797 63.7551 157.535 63.7551C157.273 63.7551 157.062 63.6822 156.902 63.5364C156.741 63.376 156.661 63.1573 156.661 62.8803V56.9979C156.661 56.0211 156.487 55.3141 156.137 54.8767C155.788 54.4394 155.235 54.2207 154.478 54.2207C153.604 54.2207 152.905 54.5195 152.381 55.1173C151.872 55.715 151.617 56.5241 151.617 57.5446V62.8803C151.617 63.1573 151.537 63.376 151.377 63.5364C151.217 63.6822 151.006 63.7551 150.744 63.7551C150.482 63.7551 150.263 63.6822 150.089 63.5364C149.928 63.376 149.848 63.1573 149.848 62.8803V53.6521C149.848 53.3897 149.928 53.1783 150.089 53.018C150.263 52.8576 150.482 52.7774 150.744 52.7774C150.991 52.7774 151.195 52.8576 151.355 53.018C151.515 53.1637 151.595 53.3678 151.595 53.6303V54.6799C151.916 54.053 152.36 53.5719 152.927 53.2366C153.495 52.9013 154.15 52.7337 154.893 52.7337C156.552 52.7337 157.637 53.4334 158.146 54.833C158.452 54.1915 158.918 53.6813 159.544 53.3022C160.17 52.9232 160.883 52.7337 161.684 52.7337Z" fill="white"/>
<path d="M176.523 52.7774C176.8 52.7774 177.011 52.8576 177.156 53.018C177.317 53.1783 177.397 53.3897 177.397 53.6521V62.9022C177.397 63.1501 177.317 63.3542 177.156 63.5145C177.011 63.6749 176.8 63.7551 176.523 63.7551C176.261 63.7551 176.05 63.6822 175.89 63.5364C175.744 63.376 175.671 63.1719 175.671 62.9241V61.8307C175.337 62.4721 174.864 62.9605 174.252 63.2958C173.655 63.6311 172.978 63.7988 172.221 63.7988C170.955 63.7988 170.001 63.4489 169.361 62.7491C168.72 62.0348 168.4 60.9851 168.4 59.6002V53.6521C168.4 53.3897 168.48 53.1783 168.64 53.018C168.8 52.8576 169.019 52.7774 169.295 52.7774C169.572 52.7774 169.783 52.8576 169.928 53.018C170.089 53.1783 170.169 53.3897 170.169 53.6521V59.5564C170.169 60.504 170.358 61.2038 170.736 61.6557C171.115 62.0931 171.712 62.3118 172.527 62.3118C173.459 62.3118 174.208 62.0129 174.776 61.4152C175.344 60.8029 175.628 60.0011 175.628 59.0097V53.6521C175.628 53.3897 175.708 53.1783 175.868 53.018C176.028 52.8576 176.247 52.7774 176.523 52.7774Z" fill="white"/>
<path d="M186.034 52.7337C188.582 52.7337 189.855 54.1405 189.855 56.9542V62.8803C189.855 63.1573 189.775 63.376 189.615 63.5364C189.47 63.6822 189.259 63.7551 188.982 63.7551C188.705 63.7551 188.487 63.6822 188.327 63.5364C188.167 63.376 188.087 63.1573 188.087 62.8803V57.0635C188.087 56.0722 187.89 55.3505 187.497 54.8986C187.119 54.4467 186.507 54.2207 185.663 54.2207C184.687 54.2207 183.909 54.5195 183.326 55.1173C182.744 55.715 182.453 56.5241 182.453 57.5446V62.8803C182.453 63.1573 182.373 63.376 182.213 63.5364C182.067 63.6822 181.856 63.7551 181.579 63.7551C181.303 63.7551 181.084 63.6822 180.924 63.5364C180.764 63.376 180.684 63.1573 180.684 62.8803V53.6521C180.684 53.3897 180.764 53.1783 180.924 53.018C181.084 52.8576 181.303 52.7774 181.579 52.7774C181.841 52.7774 182.045 52.8576 182.191 53.018C182.351 53.1783 182.431 53.3824 182.431 53.6303V54.7455C182.78 54.0895 183.268 53.5938 183.894 53.2585C184.52 52.9086 185.233 52.7337 186.034 52.7337Z" fill="white"/>
<path d="M193.99 63.7551C193.728 63.7551 193.51 63.6822 193.335 63.5364C193.175 63.376 193.095 63.1573 193.095 62.8803V53.6521C193.095 53.3751 193.175 53.1637 193.335 53.018C193.51 52.8576 193.728 52.7774 193.99 52.7774C194.252 52.7774 194.463 52.8576 194.624 53.018C194.784 53.1637 194.864 53.3751 194.864 53.6521V62.8803C194.864 63.1719 194.784 63.3906 194.624 63.5364C194.463 63.6822 194.252 63.7551 193.99 63.7551ZM193.99 50.4813C193.641 50.4813 193.357 50.3792 193.139 50.1751C192.92 49.971 192.811 49.7013 192.811 49.366C192.811 49.0453 192.92 48.7829 193.139 48.5788C193.357 48.3747 193.641 48.2726 193.99 48.2726C194.34 48.2726 194.624 48.3747 194.842 48.5788C195.06 48.7829 195.17 49.0453 195.17 49.366C195.17 49.7013 195.06 49.971 194.842 50.1751C194.624 50.3792 194.34 50.4813 193.99 50.4813Z" fill="white"/>
<path d="M203.084 62.3555C203.593 62.3993 203.848 62.6325 203.848 63.0553C203.848 63.3031 203.753 63.4926 203.564 63.6239C203.389 63.7405 203.12 63.7842 202.756 63.7551L202.166 63.7113C201.002 63.6239 200.143 63.274 199.59 62.6617C199.037 62.0494 198.76 61.1236 198.76 59.8845V54.3956H197.297C197.049 54.3956 196.853 54.3373 196.707 54.2207C196.576 54.0895 196.511 53.9218 196.511 53.7177C196.511 53.499 196.576 53.3241 196.707 53.1929C196.853 53.0617 197.049 52.9961 197.297 52.9961H198.76V50.525C198.76 50.248 198.84 50.0366 199 49.8909C199.16 49.7305 199.379 49.6503 199.655 49.6503C199.917 49.6503 200.128 49.7305 200.289 49.8909C200.449 50.0366 200.529 50.248 200.529 50.525V52.9961H202.953C203.186 52.9961 203.367 53.0617 203.499 53.1929C203.644 53.3241 203.717 53.499 203.717 53.7177C203.717 53.9218 203.644 54.0895 203.499 54.2207C203.367 54.3373 203.186 54.3956 202.953 54.3956H200.529V59.9719C200.529 60.7737 200.689 61.3569 201.009 61.7214C201.344 62.0712 201.839 62.268 202.494 62.3118L203.084 62.3555Z" fill="white"/>
<path d="M213.384 53.3241C213.544 52.9742 213.799 52.7993 214.148 52.7993C214.367 52.7993 214.563 52.8722 214.738 53.018C214.913 53.1637 215 53.346 215 53.5646C215 53.6959 214.971 53.8125 214.913 53.9145L208.776 67.1664C208.704 67.3268 208.595 67.4507 208.449 67.5382C208.318 67.6257 208.18 67.6694 208.034 67.6694C207.816 67.6694 207.619 67.5965 207.444 67.4507C207.284 67.3195 207.204 67.1446 207.204 66.9259C207.204 66.8093 207.233 66.6926 207.292 66.576L208.82 63.274L204.496 53.9145C204.438 53.8125 204.409 53.7031 204.409 53.5865C204.409 53.3678 204.504 53.1783 204.693 53.018C204.897 52.8576 205.115 52.7774 205.348 52.7774C205.494 52.7774 205.632 52.8211 205.763 52.9086C205.909 52.9961 206.018 53.12 206.091 53.2804L209.737 61.4808L213.384 53.3241Z" fill="white"/>
</svg>
</div>
        </header>
        <section className="join">
          <h1>
            Join the community, truc machin share bidule
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a
            pharetra purus. Donec scelerisque quam pharetra mi efficitur tempor.
          </p>
          {/* <div className="subscribe">
            <h2>Subscribe right now </h2>
            <div className="formGroup">
              <input type="text" />
              <button>Apply</button>
            </div>
            <div className="hint">
              We don’t care about your email. We just want you to join.
            </div>
          </div> */}

        </section>
        <section className="already-member">
          <h2>Already have an account?</h2>
          <a href="https://figma-co.slack.com">Click here baby</a>
        </section>
        <section className="feature">
          <article>
            <h1>Invite peers over, show them the way</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a pharetra purus. Donec scelerisque quam pharetra</p>
          </article>
          <article>
            <h1>Invite peers over, show them the way</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a pharetra purus. Donec scelerisque quam pharetra</p>
          </article>
        </section>
      </div>
      <section className="who">
        <div className="container">
          <div className="face aicha"></div>
          <div className="face josselin"></div>
          <h1>Who did this?</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a
            pharetra purus. Donec scelerisque quam pharetra
          </p>
          <div className="peoples">
            <article className="aicha">
              <div className="picture" />
              <h1 className="name">Aicha Barry</h1>
              <p className="description">Lead designer @ Valtech </p>
              <div className="socials">
                  <a href="#" className="linkedin"></a>
                  <a href="#" className="dribbble"></a>
              </div>
            </article>
            <article className="joss">

            <div className="picture" />
              <h1 className="name">Josselin Cuette</h1>
              <p className="description">Senior UX/UI Designe</p>
              <div className="socials">
                  <a href="#" className="linkedin"></a>
                  <a href="#" className="dribbble"></a>
              </div>
            </article>
          </div>
        </div>
      </section>
      <div className="container">
        <footer>
          <div className="logo-small">
          <svg width="17" height="32" viewBox="0 0 17 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7554 25.2116C15.7669 23.9608 15.78 22.6422 15.3331 21.0969L15.2621 20.9894C14.8152 19.4441 13.5434 18.829 12.137 19.6156L2.54646 24.9798C1.14007 25.7664 0.362241 27.6567 0.809127 29.202C1.25601 30.7473 2.75839 31.3623 4.16478 30.5757L13.7554 25.2116Z" fill="#76D7FE"/>
            <path d="M13.7553 18.9749C15.1617 19.7615 15.9395 21.6519 15.4926 23.1972C15.0457 24.7424 13.5433 25.3575 12.1369 24.5709L2.54637 19.2068C0.742624 18.0056 0.457204 16.5876 0.904089 15.0423L0.904089 14.9845C1.35097 13.4392 2.7583 12.8242 4.16469 13.6108L13.7553 18.9749Z" fill="#F24E1E"/>
            <path d="M13.7554 6.78845C15.1618 7.57506 15.8887 8.85618 15.4419 10.4015C14.995 11.9467 13.5435 13.171 12.1371 12.3844L2.54655 7.02032C1.14016 6.23371 0.362324 4.34334 0.80921 2.79805C1.2561 1.25277 2.75848 0.637743 4.16487 1.42435L13.7554 6.78845Z" fill="#62D8AB"/>
            <path d="M13.7553 13.0251C15.1617 12.2385 15.9395 10.3481 15.4926 8.80284C15.0457 7.25756 13.5433 6.64253 12.1369 7.42914L2.54637 12.7932C1.13998 13.5799 0.362151 15.4702 0.809037 17.0155C1.25592 18.5608 2.7583 19.1758 4.16469 18.3892L13.7553 13.0251Z" fill="#A259FF"/>
          </svg>
          </div>
          <div className="copyright">
            Designed by <a
              href="https://www.linkedin.com/in/josselin-cuette-2a0b6029/"
              target="_blank"
              rel="noopener noreferrer"
            >Josselin Cuette</a> / Coded by <a href="https://www.linkedin.com/in/hello8/" target="_blank"  rel="noopener noreferrer"> Jerome D.Soucy
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
